import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postRoadmap } from "../../services/redux/middleware/getEvent";
import './RoadMap-input.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; 
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
export default function RoadMap_input() {
  const dispatch = useDispatch();
  const { roadmapStatus, roadmapError } = useSelector((state) => state.getAllEvenst);
  const navigate = useNavigate();

  const [roadmapData, setRoadmapData] = useState({
    title: '',
    update1: '',
    update2: '',
    update3: '',
    month: '',
    year: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // Flag to track submission state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoadmapData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const { title, update1, update2, update3, month, year } = roadmapData;

    if (!title || !update1 || !update2 || !update3 || !month || !year) {
      toast.error('Please fill in all fields');
      return;
    }

    // Prevent multiple submissions
    if (isSubmitting) return;
    setIsSubmitting(true);  // Set submitting state to true

    dispatch(postRoadmap(roadmapData)).then(() => {
      if (roadmapStatus === 'succeeded') {
        toast.success('Roadmap successfully added!');
        navigate('/update');
      } else if (roadmapStatus === 'failed') {
        toast.error(roadmapError || 'Failed to add roadmap');
      }
    }).catch(() => {
      setIsSubmitting(false);  // Reset submitting state in case of error
    });
  };

  const handleCancel = () => {
    navigate('/update');
  };

  return (
    <div className="main_container">
      <h2 className="roadmap_text_h">Roadmap</h2>
      <div className="ovrall_input_container">
        <div className="input_text_Roadmap_container">
          <label className="roadmap-titel-text">Roadmap Title</label>
          <input
            className="Title_input"
            type="text"
            placeholder="Enter Title of the Roadmap"
            name="title"
            value={roadmapData.title}
            onChange={handleChange}
          />
        </div>
        <div className="input_text_Roadmap_container">
          <label className="roadmap-titel-text">Update 1</label>
          <textarea
            className="update_Text_input"
            placeholder="Enter Update 1"
            name="update1"
            value={roadmapData.update1}
            onChange={handleChange}
            style={{ resize: "none" }}
          />
        </div>
        <div className="input_text_Roadmap_container">
          <label className="roadmap-titel-text">Update 2</label>
          <textarea
            className="update_Text_input"
            placeholder="Enter Update 2"
            name="update2"
            value={roadmapData.update2}
            onChange={handleChange}
            style={{ resize: "none" }}
          />
        </div>
        <div className="input_text_Roadmap_container">
          <label className="roadmap-titel-text">Update 3</label>
          <textarea
            className="update_Text_input"
            placeholder="Enter Update 3"
            name="update3"
            value={roadmapData.update3}
            onChange={handleChange}
            style={{ resize: "none" }}
          />
        </div>
        <div className="input_text_Roadmap_container">
          <label className="roadmap-titel-text">Month</label>
          <input
            className="Title_input"
            type="month"
            name="month"
            value={roadmapData.month}
            onChange={handleChange}
          />
        </div>
        <div className="input_text_Roadmap_container">
          <label className="roadmap-titel-text">Year</label>
          <input
            className="Title_input"
            type="text"
            placeholder="Enter Year"
            name="year"
            value={roadmapData.year}
            onChange={(e) => {
              const value = e.target.value;

              if (/^\d*$/.test(value)) {
                handleChange(e); 
              }
            }}
          />
        </div>

        <div className="Proposal_Button_Main_1">
          <button
            onClick={handleSubmit}
            className="Proposal_Button_Main_1_Btn1"
            disabled={isSubmitting}  
          >
            {isSubmitting ? 'Submitting...' : 'Publish'}
          </button>
          <button onClick={handleCancel} className="Proposal_Button_Main_1_Btn2">
            Cancel
          </button>
        </div>
        {roadmapStatus === 'failed' && <p className="error">{roadmapError}</p>}
      </div>
    </div>
  );
}
