import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserRanks } from "../../services/redux/middleware/getEvent";
import "./Ranking.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
function Ranking() {
  const [activeButton, setActiveButton] = useState("Bronze");
  const dispatch = useDispatch();

  const { rankingData, loading, error } = useSelector(
    (state) => state.getAllEvenst
  );

  useEffect(() => {
    dispatch(getUserRanks(activeButton));
  }, [activeButton, dispatch]);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <div className="ranking__main__container">
        {loading && <ScreenLoader />}
      <div className="ranking__main__container1">
        <div className="ranking__button-group">
          <div className="ranking__button-group1">
            {["Bronze", "Silver", "Gold", "Platinum", "Diamond"].map((rank) => (
              <button
                key={rank}
                className={`button__ranking ${
                  activeButton === rank
                    ? "button__ranking-active"
                    : "button__ranking-transparent"
                }`}
                onClick={() => handleButtonClick(rank)}
              >
                {rank}
              </button>
            ))}
          </div>
        </div>

        <TableContainer
          className="ranking__table__main__container"
          component={Paper}
          style={{
            backgroundColor: "rgba(188,191,204, 0.1)",
            boxShadow: "none",
          }}
        >
          <Table
            className="for-border"
            sx={{ minWidth: 650, backgroundColor: "rgba(188,191,204, 0.1)" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell className="ranking__head__main">#</TableCell>
                <TableCell className="ranking__head__main">
                  User Image
                </TableCell>
                <TableCell className="ranking__head__main">User</TableCell>
                <TableCell className="ranking__head__main">Level</TableCell>
                <TableCell className="ranking__head__main">Referral</TableCell>
                <TableCell className="ranking__head__main">
                  Tasks Completed
                </TableCell>
                <TableCell className="ranking__head__main">
                  xMAG Earned
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="ranking__table__body">
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : error ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    {error}
                  </TableCell>
                </TableRow>
              ) : Array.isArray(rankingData) && rankingData.length > 0 ? (
                rankingData.map((ranking, index) => (
                  <TableRow key={ranking.id}>
                    <TableCell className="ranking__index_1">
                      {index + 1}
                    </TableCell>
                    <TableCell className="ranking__user__image">
                      <img
                        className="user__image_1"
                        src={
                          ranking.profilePicture
                            ? ranking.profilePicture
                            : "/Images/actor.svg"
                        }
                        alt={`${ranking.userName}'s avatar`}
                      />
                    </TableCell>
                    <TableCell className="ranking__user_name">
                      {ranking.userName}
                    </TableCell>
                    <TableCell className="ranking__level">
                      {ranking.userLevel}
                    </TableCell>
                    <TableCell className="ranking__refferal">
                      {ranking.referralCode}
                    </TableCell>
                    <TableCell className="ranking__taskcompleted">
                      {ranking.tasksCompleted}
                    </TableCell>
                    <TableCell className="ranking__xmag">
                      {ranking.userMag}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default Ranking;
