import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postNews } from "../../services/redux/middleware/getEvent";
import "./Add_news.css";
import { useNavigate } from "react-router-dom";

export default function Add_news() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.getAllEvenst);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    date: "",
    time: "",
    newsLink: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

   
    if (loading) return;

    dispatch(postNews(formData)).then((response) => {
      if (!response.error) {
        
        setFormData({
          title: "",
          description: "",
          date: "",
          time: "",
          newsLink: "",
        });
        navigate("/update");
      }
    });
  };

  const handleCancel = () => {
    navigate("/update");
  };

  return (
    <>
      <div className="main_container">
        <h2 className="roadmap_text_h">News</h2>
        
        <form className="ovrall_input_container" onSubmit={handleSubmit}>
          <div className="input_text_Roadmap_container">
            <label className="roadmap-titel-text">News Title</label>
            <input
              className="Title_input"
              type="text"
              name="title"
              placeholder="Enter Title of the News"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input_text_Roadmap_container">
            <label className="roadmap-titel-text">News Description</label>
            <textarea
              className="update_Text_input"
              name="description"
              placeholder="Enter Description of the News"
              value={formData.description}
              onChange={handleChange}
              style={{ resize: 'none' }}
            />
          </div>
          <div className="input_text_Roadmap_container">
            <label className="roadmap-titel-text">Date</label>
            <input
              className="Title_input"
              type="date"
              name="date"
              placeholder="Enter Date"
              value={formData.date}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input_text_Roadmap_container">
            <label className="roadmap-titel-text">Time</label>
            <input
              className="Title_input"
              type="time"
              name="time"
              placeholder="Enter Time"
              value={formData.time}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input_text_Roadmap_container">
            <label className="roadmap-titel-text">News Link</label>
            <input
              className="Title_input"
              type="url"
              name="newsLink"
              placeholder="Input News Link"
              value={formData.newsLink}
              onChange={handleChange}
            />
          </div>

      
          <div className="Proposal_Button_Main_1">
          <button className="Proposal_Button_Main_1_Btn1" type="submit" disabled={loading}>
              {loading ? "Publishing..." : "Publish"}
            </button>
            <button
              className="Proposal_Button_Main_1_Btn2"
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </>
  );
}
