import { createSlice } from "@reduxjs/toolkit";
import {
  getEvent,
  deleteTask,
  getQuestsByAdmin,
  addQuest,
  getAdminProposals,
  deleteDao,
  userData ,
  uploadProof,
  getUserReferrals,
  getUserRanks,
  postNews,
  getNews,
  updateNews,
  deleteNews,
  postRoadmap,
  getRoadmap,
  updateRoadmap,
  deleteRoadmap
} from "../middleware/getEvent";


const initialState = {
  loading: false,
  error: null,
  quests: [],
  daos: [],
  data: [], 
  uploadResponse: [],
  uploadStatus: "idle",
  error: null, 
  referrals: [],
  rankingData: [],
  news: [],
  newsList: [],
  roadmap: null, 
  roadmapStatus: "idle", 
  roadmapError: null,
  roadmapData: [], 
  roadmapStatus: 'idle',
  roadmaps: [],
};

const getEventReducer = createSlice({
  name: "getEvent",
  initialState,

  extraReducers: (builder) => {
    builder
    .addCase(deleteRoadmap.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(deleteRoadmap.fulfilled, (state, action) => {
      state.loading = false;
      state.roadmaps = state.roadmaps.filter((item) => item._id !== action.payload.id);
      
    })
    .addCase(deleteRoadmap.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
      .addCase(getEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEvent.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.quests.push(payload);
      })
      .addCase(getEvent.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error?.message || "Failed to fetch events";
      })
      

      .addCase(deleteTask.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.loading = false;
        state.quests = state.quests.filter((task) => task._id !== action.payload);
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(uploadProof.pending, (state) => {
        state.uploadStatus = "loading";
        state.error = null;
      })
      .addCase(uploadProof.fulfilled, (state, action) => {
        state.uploadStatus = "succeeded";
        state.uploadResponse = action.payload; 
      })
      .addCase(uploadProof.rejected, (state, action) => {
        state.uploadStatus = "failed";
        state.error = action.payload || "Failed to upload file.";
      })
      
      .addCase(getQuestsByAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getQuestsByAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.quests = payload.quests || [];
      })
      .addCase(getQuestsByAdmin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || "Failed to fetch quests";
      })

   
      .addCase(addQuest.pending, (state) => {
        state.loading = true;
      })
      .addCase(addQuest.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (Array.isArray(state.quests)) {
          state.quests = [...state.quests, payload];
        }
      })
      .addCase(addQuest.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || "Failed to add quest";
      })

     
      .addCase(getAdminProposals.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminProposals.fulfilled, (state, action) => {
        state.loading = false;
        state.daos = action.payload;
      })
      .addCase(getAdminProposals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(deleteDao.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDao.fulfilled, (state, action) => {
        state.loading = false;

        console.log("Delete action payload:", action.payload);

        state.daos = state.daos.filter((dao) => dao._id !== action.payload);
      })
      .addCase(deleteDao.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(userData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(userData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUserReferrals.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserReferrals.fulfilled, (state, action) => {
        state.loading = false;
        state.referrals = action.payload;  
      })
      .addCase(getUserReferrals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch user referrals";
      })
      .addCase(getUserRanks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserRanks.fulfilled, (state, action) => {
        state.loading = false;
        state.rankingData = action.payload || []; 
      })
      .addCase(getUserRanks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(postNews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postNews.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.news.push(payload); 
      })
      .addCase(postNews.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || "Failed to post news";
      })
      .addCase(getNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNews.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.news = payload; 
      })
      .addCase(getNews.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error?.message || "Failed to fetch news";
      })
      .addCase(updateNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNews.fulfilled, (state, action) => {
        state.loading = false;
        const updatedNews = action.payload;
        state.newsList = state.newsList.map((news) =>
          news.id === updatedNews.id ? updatedNews : news
        );
      })
      .addCase(updateNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to update news";
      })
      .addCase(deleteNews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteNews.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.news = state.news.filter((newsItem) => newsItem._id !== payload); 
      })
      .addCase(deleteNews.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || "Failed to delete news";
      })
      .addCase(postRoadmap.pending, (state) => {
        state.roadmapStatus = "loading";
        state.roadmapError = null;
      })
      .addCase(postRoadmap.fulfilled, (state, { payload }) => {
        state.roadmapStatus = "succeeded";
        state.roadmap = payload;
      })
      .addCase(postRoadmap.rejected, (state, { payload }) => {
        state.roadmapStatus = "failed";
        state.roadmapError = payload || "Failed to post roadmap";
      })
      .addCase(getRoadmap.pending, (state) => {
        state.roadmapStatus = 'loading';
        state.error = null;
      })
      .addCase(getRoadmap.fulfilled, (state, action) => {
        state.roadmapStatus = 'succeeded';
        state.roadmapData = Array.isArray(action.payload.roadmap) ? action.payload.roadmap : [];
      
      })
      .addCase(getRoadmap.rejected, (state, action) => {
        state.roadmapStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(updateRoadmap.pending, (state) => {
        state.roadmapStatus = "loading";
        state.roadmapError = null;
      })
      .addCase(updateRoadmap.fulfilled, (state, { payload }) => {
        state.roadmapStatus = "succeeded";
      
        state.roadmapData = state.roadmapData.map((roadmap) =>
          roadmap.id === payload.id ? payload : roadmap
        );
      })
      .addCase(updateRoadmap.rejected, (state, { payload }) => {
        state.roadmapStatus = "failed";
        state.roadmapError = payload || "Failed to update roadmap";
      })
    
  },
  
});

export default getEventReducer.reducer;
