import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateDao } from "../../services/redux/middleware/getEvent";
import "./EditProposal.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";

function EditProposal() {
  const location = useLocation();
  const proposalData = location.state?.proposalData;

  const [formData, setFormData] = useState({
    title: proposalData?.title || "",
    description: proposalData?.description || "",
    question: proposalData?.question || "",
    optionA: proposalData?.options[0] || "",
    optionB: proposalData?.options[1] || "",
    optionC: proposalData?.options[2] || "",
    optionD: proposalData?.options[3] || "",
    fees: proposalData?.fees || "",
    minimumBalance: proposalData?.minimumBalance || "",
    correctOption: proposalData?.correctOption || null,
    _id: proposalData?._id || "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Handle correctOption field when an option field is cleared
    if (name === `option${formData.correctOption}` && value === "") {
      setFormData((prevData) => ({
        ...prevData,
        correctOption: null,
      }));
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Set correct option based on user selection
  const handleSelectOption = (option) => {
    setFormData((prevData) => ({
      ...prevData,
      correctOption: option,
    }));
  };

  // Publish the updated proposal
  const handlePublish = () => {
    if (!formData._id) {
      toast.error("Proposal ID (_id) is missing");
      return;
    }

    dispatch(updateDao({ id: formData._id, daoData: formData }))
      .then(() => {
        toast.success("Proposal updated successfully!");
        navigate("/dao");
      })
      .catch((error) => {
        toast.error(`Failed to update proposal: ${error.message}`);
      });
  };

  // Cancel and navigate to the previous page
  const handleCancel = () => {
    navigate("/dao");
  };

  // Log formData for debugging purposes
  useEffect(() => {
    console.log("Form Data:", formData);
  }, [formData]);

  return (
    <div className="edit__proposal__main__container">
      <div className="Proposal_Main">
        <p className="Proposal_FirstP_tag">Edit Proposal</p>

        <div className="Proposal_Main__Div1">
          {/* Title Input */}
          <div className="Proposal_Main__Div1_Div1">
            <p className="Proposal_Main__Div1_Div1_Ptag">Proposal Title</p>
            <input
              className="Proposal_Main__Div1_Div1_InputFeild"
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Enter Title of the Proposal"
            />
          </div>

          {/* Description Input */}
          <div className="Proposal_Main__Div1_Div1">
            <p className="Proposal_Main__Div1_Div1_Ptag">Proposal Description</p>
            <textarea
              className="Proposal_Main__Div1_Div1_InputFeild_2"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter Description of the Proposal"
              style={{ resize: "none" }}
            />
          </div>

          {/* Question Input */}
          <div className="Proposal_Main__Div1_Div1">
            <p className="Proposal_Main__Div1_Div1_Ptag">Proposal Question</p>
            <input
              className="Proposal_Main__Div1_Div1_InputFeild"
              type="text"
              name="question"
              value={formData.question}
              onChange={handleInputChange}
              placeholder="Enter Question of the Proposal"
            />
          </div>

          {/* Option A, B, C, D */}
          {["A", "B", "C", "D"].map((option) => (
            <div className="Proposal_Main__Div1_Div1" key={option}>
              <p className="Proposal_Main__Div1_Div1_Ptag">Option {option}</p>
              <input
                className={`Proposal_Main__Div1_Div1_InputFeild ${
                  formData.correctOption === option ? "selected" : ""
                }`}
                type="text"
                name={`option${option}`}
                value={formData[`option${option}`]}
                onChange={handleInputChange}
                placeholder={`Enter Option ${option}`}
                onClick={() => handleSelectOption(option)} // Set selected option as correct
              />
            </div>
          ))}

          {/* Fees */}
          <div className="Proposal_Main__Div1_Div1">
            <p className="Proposal_Main__Div1_Div1_Ptag">Fees Per Vote</p>
            <input
              className="Proposal_Main__Div1_Div1_InputFeild"
              type="text"
              name="fees"
              value={formData.fees}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e); // Update state if input is valid (numeric)
                }
              }}
              placeholder="Enter Fees Per Vote"
            />
          </div>

          {/* Minimum Balance (xMAG per vote) */}
          <div className="Proposal_Main__Div1_Div1">
            <p className="Proposal_Main__Div1_Div1_Ptag">xMAG per vote</p>
            <input
              className="Proposal_Main__Div1_Div1_InputFeild"
              type="text"
              name="minimumBalance"
              value={formData.minimumBalance}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e); // Only accept numeric values
                }
              }}
              placeholder="Enter xMAG per vote"
            />
          </div>
        </div>

        {/* Publish and Cancel Buttons */}
        <div className="Proposal_Button_Main_1">
          <button onClick={handlePublish} className="Proposal_Button_Main_1_Btn1">
            Publish
          </button>
          <button onClick={handleCancel} className="Proposal_Button_Main_1_Btn2">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditProposal;
