import React, { useState, useEffect } from "react";
import "./UserPage.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { userData } from "../../services/redux/middleware/getEvent";
import { toggleUserActivation } from "../../services/redux/middleware/getEvent";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
function UserPage() {
  const [signupFrom, setSignupFrom] = useState("email");
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.getAllEvenst);
  const [activeButton, setActiveButton] = useState("Email");

  
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setSignupFrom(buttonName.toLowerCase());
  };

  
  useEffect(() => {
    dispatch(userData({ signupFrom }));
  }, [signupFrom, dispatch]);

  const toggleActivation = (userId) => {
    dispatch(toggleUserActivation(userId))
      .unwrap()
      .then((response) => {
        console.log("User activation toggled:", response);
       
        dispatch(userData({ signupFrom }));
      })
      .catch((error) => {
        console.error("Error toggling activation:", error);
      });
  };

  return (
    <div className="users__main__container">
      <div className="users__main__container1">
        <div className="users__buttons-container">
          <div className="users__button-wrapper">
            {/* Email Button */}
            <button
              className={`btnusers ${
                activeButton === "Email"
                  ? "active_button_user"
                  : "transparent__button_user"
              }`}
              onClick={() => handleButtonClick("Email")}
            >
              Email
            </button>

            {/* Telegram Button */}
            <button
              className={`btnusers ${
                activeButton === "Telegram"
                  ? "active_button_user"
                  : "transparent__button_user"
              }`}
              onClick={() => handleButtonClick("Telegram")}
            >
              Telegram
            </button>
          </div>
        </div>

        {/* User Table */}
        <TableContainer
          className="users__table__main__container"
          component={Paper}
          style={{
            backgroundColor: "rgba(188,191,204, 0.1)",
            boxShadow: "none",
          }}
        >
          <Table
            className="for-border"
            sx={{ minWidth: 650, backgroundColor: "rgba(188,191,204, 0.1)" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell className="users__head__main">#</TableCell>
                <TableCell className="users__head__main">User Image</TableCell>
                <TableCell className="users__head__main">Name</TableCell>
                {activeButton === "Telegram" && (
                  <TableCell className="users__head__main">
                    Telegram Username
                  </TableCell>
                )}
                <TableCell className="users__head__main">Email</TableCell>
                <TableCell className="users__head__main">
                  {activeButton === "Telegram"
                    ? "Referral ID"
                    : "Referral Code"}
                </TableCell>
                <TableCell className="users__head__main">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="users__table__body">
            {loading && <ScreenLoader />}
              {loading && (
                <TableRow>
                  <TableCell colSpan={7} className="users__loading">
                    Loading...
                  </TableCell>
                </TableRow>
              )}

              {/* Error State */}
              {error && (
                <TableRow>
                  <TableCell colSpan={7} className="users__error">
                    Error: {error}
                  </TableCell>
                </TableRow>
              )}

              {/* Data Rows */}
              {data && data.length > 0 ? (
                data.map((user, index) => (
                  <TableRow key={user.id}>
                    <TableCell className="users__index_1">{index + 1}</TableCell>
                    <TableCell className="users__user__image">
                      <img
                        className="user__image_1"
                        src={'/Images/actor.svg'}
                        alt={`${user.userName}'s avatar`}
                      />
                    </TableCell>
                    <TableCell className="users__user_name">
                      {user.userName}
                    </TableCell>
                    {activeButton === "Telegram" && (
                      <TableCell className="users__telegram_user">
                        {user.telegramUsername}
                      </TableCell>
                    )}
                    <TableCell className="users__email">{user.email}</TableCell>
                    <TableCell className="users__refferalcode">
                      {user.referralCode}
                    </TableCell>
                    <TableCell className="users__button">
                      <button
                        className={
                          user.isActive
                            ? "user__deactivate__button"
                            : "user__activate__button"
                        }
                        onClick={() => toggleActivation(user._id)} 
                      >
                        {user.isActive ? "Deactivate" : "Activate"}
                      </button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                // No Data Found
                !loading && (
                  <TableRow>
                    <TableCell colSpan={7} className="users__no_data">
                      No data found for {activeButton} users.
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default UserPage;
