import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./update.css";
import DeleteNewsModal from "../../Components/Modal/DeleteNews";
import DeleteRoadmapModal from "../../Components/Modal/DeleteRoadmap";
import { getRoadmap } from "../../services/redux/middleware/getEvent";
import editIcon from "../../assets/taskimages/edit1.svg";
import deleteIcon from "../../assets/taskimages/delete.svg";
import { getNews } from "../../services/redux/middleware/getEvent";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
const UpdatePage = () => {
  const [modalShow, setModalShow] = useState(false);
  const [activeButton, setActiveButton] = useState("News");
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [selectedRoadmapId, setSelectedRoadmapId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { news, loading, error } = useSelector((state) => state.getAllEvenst);
  const { roadmapData, roadmapStatus } = useSelector(
    (state) => state.getAllEvenst
  );
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleClose = () => setModalShow(false);
  const handleDeleteClick = (NewsId) => {
    setSelectedNewsId(NewsId);
    setModalShow(true);
    console.log("Delete new Item:", NewsId);
  };

  const handleRoadMapDeleteClick = (RoadMapId) => {
    setSelectedRoadmapId(RoadMapId);
    setModalShow(true);
    console.log("Delete Road Map Item:", RoadMapId);
  };

  useEffect(() => {
    dispatch(getNews());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRoadmap())
      .then((response) => {
        console.log("Roadmap response:", response);
        console.log("Payload:", response.payload);
      })
      .catch((error) => console.error("API Error:", error));
  }, [dispatch]);
  

  console.log("roadmap data", roadmapData);

  const handleEditClick = (row) => {
    console.log("Editing News Item:", row);
    navigate("/EditNews", { state: { newsItem: row } });
  };

  const handleEditRoadMapClick = (item) => {
    console.log("Editing Road Map Item:", item);
    navigate("/EditRoadMap", { state: { roadmapItem: item } });
  };

  return (
    <div className={`main ${modalShow ? "blur-background" : ""}`}>
      <div className="component-contair">
        <div className="buttons-container">
          <div className="button-wrapper">
            <button
              className={`btn ${
                activeButton === "News" ? "active" : "transparent"
              }`}
              onClick={() => handleButtonClick("News")}
            >
              News
            </button>
            <button
              className={`btn ${
                activeButton === "Roadmap" ? "active" : "transparent"
              }`}
              onClick={() => handleButtonClick("Roadmap")}
            >
              Roadmap
            </button>
          </div>
        </div>
        <div className="add-button">
          {activeButton === "News" && (
            <button
              className="Combine-button"
              onClick={() => navigate("/AddNews")}
            >
              + Add News
            </button>
          )}
          {activeButton === "Roadmap" && (
            <button
              className="Combine-button"
              onClick={() => navigate("/RoadMapAdd")}
            >
              + Add Roadmap
            </button>
          )}
        </div>
      </div>

      {activeButton === "News" && (
        <TableContainer component={Paper} sx={{ mt: 4 }} className="tableMain">
          <Table aria-label="custom table">
            <TableHead className="header_style_Main">
              <TableRow>
                <TableCell
                  className="header_Item_styling"
                  sx={{ width: "9.14%" }}
                >
                  #
                </TableCell>
                <TableCell
                  className="header_Title_styling"
                  sx={{ width: "16.45%" }}
                >
                  Title
                </TableCell>
                <TableCell
                  className="header_Description_styling"
                  sx={{ width: "18.29%" }}
                >
                  Description
                </TableCell>
                <TableCell
                  className="header_Date_styling"
                  sx={{ width: "12.80%" }}
                >
                  Date
                </TableCell>
                <TableCell
                  className="header_time_styling"
                  sx={{ width: "12.80%" }}
                >
                  Time
                </TableCell>
                <TableCell
                  className="header_link_styling"
                  sx={{ width: "16.45%" }}
                >
                  Link
                </TableCell>
                <TableCell
                  className="header_Action_styling"
                  sx={{ width: "14.08%" }}
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
              {loading ? (
                
                <TableRow>
                  <TableCell colSpan={7}>Loading...</TableCell>
                  <ScreenLoader />
                </TableRow>
              ) : error ? (
                <TableRow>
                  <TableCell colSpan={7}>Error: {error}</TableCell>
                </TableRow>
              ) : news.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7}>No news available.</TableCell>
                </TableRow>
              ) : (
                news.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell className="Class_id">{index + 1}</TableCell>
                    <TableCell className="class__update__title">
                      <strong className="Class_Title">{row.title}</strong>
                    </TableCell>
                    <TableCell className="ClassDescription">
                      {row.description}
                    </TableCell>
                    <TableCell className="Class_date">{row.date}</TableCell>
                    <TableCell className="Class_Time">{row.time}</TableCell>
                    <TableCell className="Class_Link">
                      <a
                        href={row.newsLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        link.com
                      </a>
                    </TableCell>
                    <TableCell className="action_styling">
                      <div className="proposal__button__container">
                        <img
                          src={editIcon}
                          alt="Edit item"
                          onClick={() => handleEditClick(row)}
                        />
                        <img
                          className="delete__task__icon1"
                          src={deleteIcon}
                          onClick={() => handleDeleteClick(row._id)}
                          alt="Delete item"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {activeButton === "Roadmap" && (
        <TableContainer
          component={Paper}
          sx={{ mt: 4, width: "100%" }}
          className="tableMain"
        >
          <Table aria-label="custom table">
            <TableHead className="header_style_Main">
              <TableRow>
                <TableCell className="header_Road_Map_Item_styling">
                  #
                </TableCell>
                <TableCell className="header_Road_Map_Month_styling">
                  Month
                </TableCell>
                <TableCell className="header_Road_Map_Year_styling">
                  Year
                </TableCell>
                <TableCell className="header_Road_Map_title_styling">
                  Title
                </TableCell>
                <TableCell className="header_Road_Map_Status_styling">
                  Status
                </TableCell>
                <TableCell
                  className="header_Road_Map_Action_styling"
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roadmapStatus === "loading" ? (
                <TableRow>
                  <TableCell colSpan={6}>Loading...</TableCell>
                </TableRow>
              ) : roadmapData && roadmapData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6}>No roadmap items available.</TableCell>
                </TableRow>
              ) : (
                Array.isArray(roadmapData) &&
                roadmapData.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell className="Class_id">{index + 1}</TableCell>
                    <TableCell className="class__update__month">
                      {item.month}
                    </TableCell>
                    <TableCell className="Class_year">{item.year}</TableCell>
                    <TableCell className="Class_Title">{item.title}</TableCell>
                    <TableCell
                      className={`Class_Status ${
                        item.status === "finished"
                          ? "status-finished"
                          : "status-unfinished"
                      }`}
                    >
                      {item.status}
                    </TableCell>

                    <TableCell className="action_styling">
                      <div className="proposal__button__container">
                        <img
                          src={editIcon}
                          alt="Edit item"
                          onClick={() => handleEditRoadMapClick(item)}
                        />
                        <img
                          className="delete__task__icon1"
                          src={deleteIcon}
                          onClick={() => handleRoadMapDeleteClick(item._id)}
                          alt="Delete item"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {modalShow &&
        (activeButton === "News" ? (
          <DeleteNewsModal
            show={modalShow}
            handleClose={handleClose}
            NewsId={selectedNewsId}
          />
        ) : (
          <DeleteRoadmapModal
            show={modalShow}
            handleClose={handleClose}
            RoadMapId={selectedRoadmapId || ""}
          />
        ))}
    </div>
  );
};

export default UpdatePage;
