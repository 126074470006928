import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Tasks.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import editIcon from "../../assets/taskimages/edit1.svg";
import deleteIcon from "../../assets/taskimages/delete.svg";
import DeleteTaskModal from "../../Components/Modal/DeleteTask";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";

function Tasks() {
  const location = useLocation();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [activeButton, setActiveButton] = useState("Live");
  const [selectedTaskId, setSelectedTaskId] = useState(null);

  const handleEditTaskClick = () => {
    navigate("/edittask");
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleClose = () => {
    setModalShow(false);
    setSelectedTaskId(null);
  };

  const handleDeleteClick = (taskId) => {
    setSelectedTaskId(taskId);
    setModalShow(true);
  };

  useEffect(() => {
    setLoading(true); // Start loading
    const storedTasks = JSON.parse(localStorage.getItem("tasks")) || [];
    const updatedTasks = storedTasks.map((task) => ({
      ...task,
      completedBy: Array.isArray(task.completedBy) ? task.completedBy : [],
    }));
    setTasks(updatedTasks);
    setLoading(false); // Stop loading
  }, []);

  useEffect(() => {
    if (tasks.length > 0) {
      localStorage.setItem("tasks", JSON.stringify(tasks));
    }
  }, [tasks]);

  useEffect(() => {
    const newTask = location.state?.formData;
    if (newTask) {
      setTasks((prevTasks) => {
        const taskExists = prevTasks.some((task) => task.id === newTask.id);
        if (!taskExists) {
          return [...prevTasks, newTask];
        }
        return prevTasks;
      });
    }
  }, [location.state?.formData]);

  return (
    <div className="tasks__page__main__container">
      {loading && <ScreenLoader />}
      {!loading && (
        <>
          <div className="buttons-container">
            <div className="button-wrapper">
              <button
                className={`btn ${
                  activeButton === "Live" ? "active" : "transparent"
                }`}
                onClick={() => handleButtonClick("Live")}
              >
                Live
              </button>
              <button
                className={`btn ${
                  activeButton === "Ended" ? "active" : "transparent"
                }`}
                onClick={() => handleButtonClick("Ended")}
              >
                Ended
              </button>
            </div>
          </div>
          <TableContainer
            className="task__table__main__container"
            component={Paper}
            style={{
              backgroundColor: "rgba(188,191,204, 0.1)",
              boxShadow: "none",
            }}
          >
            <Table
              className="for-border"
              sx={{ minWidth: 650, backgroundColor: "rgba(188,191,204, 0.1)" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className="table__head__main">Task Image</TableCell>
                  <TableCell className="table__head__main">
                    Title / Description
                  </TableCell>
                  <TableCell className="table__head__main">
                    Task Completion
                  </TableCell>
                  <TableCell className="table__head__main">Rewards</TableCell>
                  <TableCell className="table__head__main">Date Started</TableCell>
                  <TableCell className="table__head__main">Date Ended</TableCell>
                  <TableCell className="table__head__main">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table__task__body">
                {tasks.length > 0 ? (
                  tasks.map((task) => (
                    <TableRow key={task.id}>
                      <TableCell align="center">
                        <div className="task__title__image_container">
                          {task.image && (
                            <img
                              className="task__title__image"
                              src={
                                task.image instanceof Blob
                                  ? URL.createObjectURL(task.image)
                                  : task.image
                              }
                              alt={task.title}
                            />
                          )}
                        </div>
                      </TableCell>
                      <TableCell className="title__task__table">
                        {task.title}
                        <span className="subtitle__task__table">
                          <br />
                          {task.description}
                        </span>
                      </TableCell>
                      <TableCell className="table__task__completion">
                        {Array.isArray(task.completedBy)
                          ? task.completedBy.length
                          : 0}
                      </TableCell>
                      <TableCell className="table__task__rewards">
                        {task.amount}
                      </TableCell>
                      <TableCell className="table__task__start_date">
                        15/10/24
                      </TableCell>
                      <TableCell className="table__task__end_date">
                        23/11/24
                      </TableCell>
                      <TableCell className="task__image_icon">
                        <div style={{ display: "flex" }}>
                          <img
                            onClick={handleEditTaskClick}
                            src={editIcon}
                            alt="edit"
                          />
                          <img
                            onClick={() => handleDeleteClick(task.id)}
                            className="delete__task__icon"
                            src={deleteIcon}
                            alt="delete"
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No Task Data Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <DeleteTaskModal
            show={modalShow}
            handleClose={handleClose}
            taskId={selectedTaskId}
            setTasks={setTasks}
          />
        </>
      )}
    </div>
  );
}

export default Tasks;
