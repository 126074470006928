import React from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "./DeleteTask.css";
import { useDispatch } from "react-redux";
import { deleteDao } from "../../services/redux/middleware/getEvent";
import { toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css"; 
const DeleteProposalModal = ({ show, handleClose, proposalId }) => {
  const dispatch = useDispatch();

  const handleDeleteProposal = () => {
    if (proposalId) {
      dispatch(deleteDao(proposalId))
        .unwrap() 
        .then(() => {
          toast.success("Proposal deleted successfully");
          handleClose();  
        })
        .catch((error) => {
          toast.error("Failed to delete proposal: " + error);  
          handleClose();  
        });
    } else {
      toast.error("Invalid proposal ID");
      handleClose();  
    }
  };
  

  
  return (
    <Modal
      className="modal__main__container"
      show={show}
      onHide={handleClose}
      centered
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ModalBody>
        <ModalHeader className="close__image_container-1">
          <div className="close__image_container" onClick={handleClose}>
            <img src="/Images/Modal/close.svg" alt="Close" />
          </div>
        </ModalHeader>
        <div className="delete__task__modal_container">
          <div className="delete__task__modal__container_1">
            <div className="delete__task__modal__heading_container">
              <img
                className="logo__modal__image"
                src="/Images/Modal/logo.svg"
                alt="image"
              />
              <p className="delete__task_text">Delete Proposal</p>
            </div>
            <div className="delete__modal__button_container">
              <button onClick={handleClose} className="delete__modal_cancel_button">
                Cancel
              </button>
              <button
                className="delete__modal_delete_button"
                onClick={handleDeleteProposal} 
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteProposalModal;
