import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

// Helper function to handle API responses
const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return { status: response?.status, data: response?.data };
  } catch (error) {
    return {
      message: error?.response?.data?.error || "Something went wrong",
      status: error?.response?.status,
    };
  }
};

// Fetch all events
export const getEvent = createAsyncThunk("getEvent", async () => {
  return await handleApiResponse(() =>
    api.get(`${API_URL}/event/getEventsAll`)
  );
});

export const getEventbyid = createAsyncThunk("getEventbyid", async (data) => {
  return await handleApiResponse(() =>
    api.get(`${API_URL}/event/getEventById/${data?.id}`)
  );
});

export const addQuest = createAsyncThunk(
  "quests/addQuest",
  async (questData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/quests/add-quest`,
        questData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to add quest"
      );
    }
  }
);

export const uploadProof = createAsyncThunk(
  "quests/uploadProof",
  async (formData, { rejectWithValue }) => {
    try {
      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }

      const response = await axios.post(
        `${API_URL}/quests/upload-proof`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error.response || error.message);

      return rejectWithValue(
        error.response?.data?.message || "Failed to upload file"
      );
    }
  }
);

export const updateTask = createAsyncThunk(
  "quests/updateQuest",
  async ({ questId, formData, imageData }, { rejectWithValue }) => {
    console.log("cccccccccccccccccccccc", imageData);
    console.log(questId);

    if (!questId) {
      return rejectWithValue("Quest ID (_id) is missing");
    }

    try {
      console.log("check data", questId);
      const response = await axios.put(
        `${API_URL}/quests/update-quest/${questId}`,
        formData,
        imageData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update "
      );
    }
  }
);

export const getQuestsByAdmin = createAsyncThunk(
  "quests/getQuestsByAdmin",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/quests/get-questsbyAdmin`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch tasks"
      );
    }
  }
);

export const deleteTask = createAsyncThunk(
  "tasks/delete",
  async (questId, { rejectWithValue }) => {
    if (!questId) return rejectWithValue("Task ID is undefined");

    try {
      await axios.delete(`${API_URL}/quests/delete-quest/${questId}`);
      return questId;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete task"
      );
    }
  }
);

// Create a DAO
export const createDao = createAsyncThunk(
  "dao/createDao",
  async (daoData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/DAO/create-dao`, daoData, {
        headers: { "Content-Type": "application/json" },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to create DAO"
      );
    }
  }
);


export const getAdminProposals = createAsyncThunk(
  "dao/getAdminProposals",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/DAO/admin-proposals`);
      return response.data.daos;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch admin proposals"
      );
    }
  }
);

// Delete a DAO
export const deleteDao = createAsyncThunk(
  "dao/deleteDao",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`${API_URL}/DAO/delete-dao/${id}`);

      if (response?.data?.msg === "Deleted Successfully.") {
        dispatch(getAdminProposals());
      }

      return id;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete DAO"
      );
    }
  }
);

// Update a DAO
export const updateDao = createAsyncThunk(
  "dao/updateDao",
  async ({ id, daoData }, { rejectWithValue }) => {
    if (!id) {
      return rejectWithValue("Proposal ID (_id) is missing");
    }

    try {
      const response = await axios.put(
        `${API_URL}/DAO/update-dao/${id}`,
        daoData,
        { headers: { "Content-Type": "application/json" } }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update DAO"
      );
    }
  }
);

export const userData = createAsyncThunk(
  "email/getEmail",
  async ({ signupFrom }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/user/get-users`, {
        params: { signupFrom },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch users"
      );
    }
  }
);

export const toggleUserActivation = createAsyncThunk(
  "user/toggleUserActivation",
  async (id, { rejectWithValue }) => {
    if (!id) {
      return rejectWithValue("User ID is missing");
    }

    try {
      const response = await axios.get(`${API_URL}/user/activate-deactivate-user/${id}`);
      return response.data; 
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to toggle user activation"
      );
    }
  }
);

export const getUserReferrals = createAsyncThunk(
  "user/getUserReferrals", 
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/user/get-users-refferrals`);
      console.log("API Response:", response.data);  
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch user referrals"
      );
    }
  }
);

export const getUserRanks = createAsyncThunk(
  "user/getUserRanks",
  async (rank, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/rank/get-user-ranks`, {
        params: { rank } 
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch user ranks"
      );
    }
  }
);

export const postNews = createAsyncThunk(
  "news/postNews",
  async ({ title, news, description, date, time, newsLink }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/news/post-news`,
        { title, news, description, date, time, newsLink },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to post news"
      );
    }
  }
);
export const getNews = createAsyncThunk("news/getNews", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${API_URL}/news/get-news`);
    return response.data.news; 
  } catch (error) {
    return rejectWithValue(
      error?.response?.data?.message || "Failed to fetch news"
    );
  }
});


export const deleteNews = createAsyncThunk(
  "news/deleteNews",
  async (id, { rejectWithValue }) => {
    if (!id) {
      return rejectWithValue("News ID is missing");
    }

    try {
      const response = await axios.delete(`${API_URL}/news/delete-news/${id}`);
      return { id, message: response?.data?.message || "Deleted successfully" };
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete news"
      );
    }
  }
);


export const updateNews = createAsyncThunk(
  "news/updateNews",
  async ({ id, title, description, date, time, newsLink }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${API_URL}/news/update-news/${id}`,
        { title, description, date, time, newsLink },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update news"
      );
    }
  }
);

export const postRoadmap = createAsyncThunk(
  "roadmap/postRoadmap",
  async ({ title, month, year, update1, update2, update3 }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/roadmap/post-roadmap`,
        { title, month, year, update1, update2, update3 },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to post roadmap"
      );
      
    }
  }
);
export const getRoadmap = createAsyncThunk(
  'roadmap/getRoadmap',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/roadmap/get-roadmap`);
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch roadmap');
    }
  }
);
export const updateRoadmap = createAsyncThunk(
  "roadmap/updateRoadmap",
  async ({ id, title, month, year, update1, update2, update3 }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${API_URL}/roadmap/update-roadmap/${id}`,
        { title, month, year, update1, update2, update3 },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      
      console.error('Error in updateRoadmap:', error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to update roadmap"
      );
    }
  }
);

export const deleteRoadmap = createAsyncThunk(
  "roadmap/deleteRoadmap",
  async (id, { rejectWithValue }) => {
    if (!id) {
      return rejectWithValue("Roadmap ID is missing");
    }

    try {
      const response = await axios.delete(`${API_URL}/roadmap/delete-roadmap/${id}`);
      console.log("Delete Response:", response.data);
      return { id, message: response?.data?.message || "Deleted successfully" };
    } catch (error) {
      console.error("Delete Error:", error.response?.data);
      return rejectWithValue(error.response?.data?.message || "Failed to delete roadmap");
    }
    
  }
);



