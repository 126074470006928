import { useDispatch } from "react-redux";
import { createDao } from "../../services/redux/middleware/getEvent";
import "./AddProposal.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

function AddProposal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    question: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    minimumBalance: "",
    fees: "",
    correctOption: null,
  });

  const [loading, setLoading] = useState(false); // Add loading state

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === `option${formData.correctOption}` && value === "") {
      setFormData((prevData) => ({
        ...prevData,
        correctOption: null,
      }));
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectOption = (option) => {
    setFormData((prevData) => ({
      ...prevData,
      correctOption: option,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      title,
      description,
      question,
      optionA,
      optionB,
      optionC,
      optionD,
      minimumBalance,
      fees,
      correctOption,
    } = formData;

    // Validation
    if (!title || !description || !question || !fees || !minimumBalance || correctOption === null) {
      toast.error("Please fill in all required fields and select a correct option.");
      return;
    }

    if (![optionA, optionB, optionC, optionD].includes(formData[`option${correctOption}`])) {
      toast.error("The correct option must match one of the provided options.");
      return;
    }

    const daoData = {
      question,
      description,
      title,
      options: [optionA, optionB, optionC, optionD],
      fees,
      minimumBalance,
      correctOption,
    };

    setLoading(true); // Set loading to true before dispatch

    dispatch(createDao(daoData))
      .unwrap()
      .then(() => {
        toast.success("DAO created successfully!");
        navigate("/dao");
      })
      .catch((error) => {
        toast.error(error.message || "Failed to create DAO.");
      })
      .finally(() => {
        setLoading(false); // Reset loading state after response
      });
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure you want to discard changes?")) {
      setFormData({
        title: "",
        description: "",
        question: "",
        optionA: "",
        optionB: "",
        optionC: "",
        optionD: "",
        minimumBalance: "",
        fees: "",
        correctOption: null,
      });
    }
  };

  return (
    <div className="edit__proposal__main__container">
      <div className="Proposal_Main">
        <p className="Proposal_FirstP_tag">Add Proposal</p>

        <div className="Proposal_Main__Div1">
          {/* Title Input */}
          <div className="Proposal_Main__Div1_Div1">
            <p className="Proposal_Main__Div1_Div1_Ptag">Proposal Title</p>
            <input
              className="Proposal_Main__Div1_Div1_InputFeild"
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Enter Title of the Proposal"
            />
          </div>

          {/* Description Input */}
          <div className="Proposal_Main__Div1_Div1">
            <p className="Proposal_Main__Div1_Div1_Ptag">Proposal Description</p>
            <textarea
              className="Proposal_Main__Div1_Div1_InputFeild_2"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter Description of the Proposal"
              style={{ resize: "none" }}
            />
          </div>

          {/* Question Input */}
          <div className="Proposal_Main__Div1_Div1">
            <p className="Proposal_Main__Div1_Div1_Ptag">Question of Proposal</p>
            <input
              className="Proposal_Main__Div1_Div1_InputFeild"
              type="text"
              name="question"
              value={formData.question}
              onChange={handleInputChange}
              placeholder="Enter Question of Proposal"
            />
          </div>

          {["A", "B", "C", "D"].map((option) => (
            <div className="Proposal_Main__Div1_Div1" key={option}>
              <p className="Proposal_Main__Div1_Div1_Ptag">Option {option}</p>
              <input
                className={`Proposal_Main__Div1_Div1_InputFeild ${
                  formData.correctOption === option ? "selected" : ""
                }`}
                type="text"
                name={`option${option}`}
                value={formData[`option${option}`]}
                onChange={handleInputChange}
                placeholder={`Enter Option ${option}`}
                onClick={() => handleSelectOption(option)}
              />
            </div>
          ))}

          <div className="Proposal_Main__Div1_Div1">
            <p className="Proposal_Main__Div1_Div1_Ptag">Fees Per Vote</p>
            <input
              className="Proposal_Main__Div1_Div1_InputFeild"
              type="text"
              name="fees"
              value={formData.fees}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) handleInputChange(e);
              }}
              placeholder="Enter Fees Per Vote"
            />
          </div>

          <div className="Proposal_Main__Div1_Div1">
            <p className="Proposal_Main__Div1_Div1_Ptag">xMAG per vote</p>
            <input
              className="Proposal_Main__Div1_Div1_InputFeild"
              type="text"
              name="minimumBalance"
              value={formData.minimumBalance}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) handleInputChange(e);
              }}
              placeholder="Enter xMAG per vote"
            />
          </div>
        </div>

        <div className="Proposal_Button_Main_1">
          <button
            onClick={handleSubmit}
            className="Proposal_Button_Main_1_Btn1"
            disabled={loading} // Disable the button while loading
          >
            {loading ? "Submitting..." : "Publish"} {/* Show loading text */}
          </button>
          <button onClick={handleCancel} className="Proposal_Button_Main_1_Btn2">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddProposal;
