import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./NewTask.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import editIcon from "../../assets/taskimages/edit1.svg";
import deleteIcon from "../../assets/taskimages/delete.svg";
import DeleteTaskModal from "../../Components/Modal/DeleteTask";
import { useDispatch, useSelector } from "react-redux";
import { getQuestsByAdmin } from "../../services/redux/middleware/getEvent";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";

function NewTask() {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("Live");
  const [modalShow, setModalShow] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  
  const dispatch = useDispatch();
  const newtasks = useSelector((state) => state.getAllEvenst.quests);
  const loading = useSelector((state) => state.getAllEvenst.loading);
  const error = useSelector((state) => state.getAllEvenst.error);

  useEffect(() => {
    dispatch(getQuestsByAdmin());
  }, [dispatch]);

  const filteredTasks = newtasks.filter((task) => task.questType === 'quest');

  const handleDeleteClick = (taskId) => {
    setSelectedTaskId(taskId);
    setModalShow(true);
  };

  const handleClose = () => setModalShow(false);

  const handleEditTaskgroupClick = (task) => {
    navigate("/edittask", {
      state: { taskData: task },
    });
  };

  const handleImageClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      alert("No URL provided for this task.");
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <div className="tasks__page__main__container">
      {modalShow && <div className="backdrop"></div>}

      {/* Show loader if data is being fetched */}
      {loading && <ScreenLoader />}

      <div className="buttons-container">
        <div className="button-wrapper">
          <button
            className={`btn ${
              activeButton === "Live" ? "active" : "transparent"
            }`}
            onClick={() => handleButtonClick("Live")}
          >
            Live
          </button>
          <button
            className={`btn ${
              activeButton === "Ended" ? "active" : "transparent"
            }`}
            onClick={() => handleButtonClick("Ended")}
          >
            Ended
          </button>
        </div>
      </div>

      <TableContainer
        className="taskgroup__table__main__container"
        component={Paper}
        style={{
          backgroundColor: "rgba(188,191,204, 0.1)",
          boxShadow: "none",
        }}
      >
        <Table
          className="for-border"
          sx={{ minWidth: 650, backgroundColor: "rgba(188,191,204, 0.1)" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className="table__head__main1">Task Image</TableCell>
              <TableCell className="table__head__main1">
                Title / Description
              </TableCell>
              <TableCell className="table__head__main1">
                Task Completion
              </TableCell>
              <TableCell className="table__head__main1">Rewards</TableCell>
              <TableCell className="table__head__main1">Date Started</TableCell>
              <TableCell className="table__head__main1">Date Ended</TableCell>
              <TableCell className="table__head__main1">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table__task__body">
            {filteredTasks.length > 0 ? (
              filteredTasks.map((task) => (
                <TableRow key={task._id}>
                  <TableCell
                    className="taskgroup__table__title_image_container"
                    align="center"
                  >
                    <div className="taskgroup__title__image_container">
                      {task.image &&
                        typeof task.image === "string" &&
                        task.image.startsWith("data:image") && (
                          <img
                            className="taskgroup__title__image"
                            src={task.image}
                            alt={task.title}
                            onClick={() => handleImageClick(task.url)}
                          />
                        )}
                      {task.image &&
                        typeof task.image === "string" &&
                        !task.image.startsWith("data:image") && (
                          <img
                            className="taskgroup__title__image"
                            src={task.image}
                            alt={task.title}
                          />
                        )}
                      {task.image && task.image instanceof Blob && (
                        <img
                          className="taskgroup__title__image"
                          src={URL.createObjectURL(task.image)}
                          alt={task.title}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="title__taskgroup__table">
                    {task.title}
                    <span className="subtitle__taskgroup__table">
                      <br />
                      {task.description}
                    </span>
                  </TableCell>
                  <TableCell className="table__taskgroup__completion">
                    {Array.isArray(task.completedBy)
                      ? task.completedBy.length
                      : 0}
                  </TableCell>
                  <TableCell className="table__taskgroup__rewards">
                    {task.points}
                  </TableCell>
                  <TableCell className="table__taskgroup_start_date">
                    15/10/24
                  </TableCell>
                  <TableCell className="table__taskgroup__end_date">
                    23/11/24
                  </TableCell>
                  <TableCell className="taskgroup__image_icon">
                    <div style={{ display: "flex" }}>
                      <img
                        onClick={() => handleEditTaskgroupClick(task)}
                        src={editIcon}
                        alt="edit"
                      />
                      <img
                        onClick={() => handleDeleteClick(task._id)}
                        className="delete__taskgroup__icon"
                        src={deleteIcon}
                        alt="delete"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Task Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteTaskModal
        show={modalShow}
        handleClose={handleClose}
        taskId={selectedTaskId}
      />
    </div>
  );
}

export default NewTask;
