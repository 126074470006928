import React, { useState, useEffect } from "react";
import "./Proposal.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import editIcon from "../../assets/taskimages/edit1.svg";
import deleteIcon from "../../assets/taskimages/delete.svg";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import { useNavigate } from "react-router-dom";
import DeleteProposalModal from "../../Components/Modal/DeleteProposal";
import { useDispatch, useSelector } from "react-redux";
import { getAdminProposals } from "../../services/redux/middleware/getEvent";

const CustomTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVotesVisible, setIsVotesVisible] = useState(false);
  const [isEyeOpen, setIsEyeOpen] = useState(false);
  const [selectedProposalId, setSelectedProposalId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  
  const { daos = [], error, loading } = useSelector((state) => state.getAllEvenst);

  useEffect(() => {
    dispatch(getAdminProposals());
  }, [dispatch]);

  const handleEditProposalClick = (proposal, event) => {
    event.preventDefault();
    console.log('Edit proposal clicked:', proposal);
    navigate("/editproposal", {
      state: { proposalData: proposal },
    });
  };

  const handleDeleteClick = (proposalId) => {
    setSelectedProposalId(proposalId);
    setModalShow(true);
  };

  if (loading) return <ScreenLoader />;

 
  if (error) return <div>Error: {error}</div>;

  const handleClick = () => {
    setIsVotesVisible(!isVotesVisible);
    setIsEyeOpen(!isEyeOpen);
  };

  return (
    <div className={`TableOverAll__Container ${modalShow ? 'blur-background' : ''}`}>
      <TableContainer
        className="proposal__table__main_container"
        component={Paper}
      >
        <Table>
          <TableHead className="TableHead">
        
            <TableRow>
              <TableCell className="TableRow_fontSet">#</TableCell>
              <TableCell className="TableRow_fontSet">Title</TableCell>
              <TableCell className="TableRow_fontSet">Description</TableCell>
              <TableCell className="TableRow_fontSet">Fees/vote</TableCell>
              <TableCell className="TableRow_fontSet">xMAG/vote</TableCell>
              <TableCell className="TableRow_fontSet">Votes</TableCell>
              <TableCell className="TableRow_Action">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {daos.length > 0 ? (
              daos.map((proposal, index) => (
                <TableRow key={proposal.id}>
                  <TableCell className="TableCellAligmnts">
                    {index + 1}
                  </TableCell>
                  <TableCell className="LoremAlign">{proposal.title}</TableCell>
                  <TableCell className="TableCellAligmnts">
                    {proposal.description}
                  </TableCell>
                  <TableCell className="TableCellAligmnts">
                    {proposal.fees}
                  </TableCell>
                  <TableCell className="TableCellAligmnts">
                    {proposal.minimumBalance}
                  </TableCell>
                  <TableCell className="TableCellAligmnts" >
                    {proposal.totalVotes}
                  </TableCell>

                  <TableCell>
                    <div className="proposal__button__container">
                      <img
                        src={editIcon}
                        alt="EditIcon"
                        onClick={(event) =>
                          handleEditProposalClick(proposal, event)
                        }
                      />
                      <img
                        onClick={() => handleDeleteClick(proposal._id)}
                        className="delete__task__icon1"
                        src={deleteIcon}
                        alt="DeleteIcon"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} style={{ textAlign: "center" }}>
                  No proposals found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteProposalModal
        show={modalShow}
        handleClose={handleClose}
        proposalId={selectedProposalId}
      />
    </div>
  );
};

export default CustomTable;
