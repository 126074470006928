import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateTask, uploadProof } from "../../services/redux/middleware/getEvent";
import "./EditTaskGroup.css";
import { toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";
function EditTaskGroup() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [title, setTitle] = useState(location.state?.taskData?.title || "");
  const [description, setDescription] = useState(location.state?.taskData?.description || "");
  const [img, setImg] = useState(location.state?.taskData?.image || null);
  const [file, setFile] = useState(null);

  const taskData = location.state?.taskData;

  const handleTaskClick = () => navigate("/TaskGroup");

  const handlePublish = async () => {
    if (!title || !description) {
     toast.error("Title and Description are required!");
      return;
    }

    let uploadedImageUrl = "";

    if (file) {
      const formData = new FormData();
      formData.append("files", file);

      const result = await dispatch(uploadProof(formData));
      if (result.payload?.length > 0) {
        uploadedImageUrl = result.payload[0];
      } else {
        toast.error("Failed to upload file.");
        return;
      }
    } else if (taskData?.image) {
      uploadedImageUrl = taskData.image;
    } else {
      toast.error("Please upload an image.");
      return;
    }

    const updatedTask = {
      title,
      description,
      image: uploadedImageUrl,
    };

    try {
      const response = await dispatch(updateTask({ questId: taskData._id, formData: updatedTask }));
      if (response.payload) {
        toast.success("Task updated successfully!");
        navigate("/TaskGroup");
      } else {
        toast.error("Task update failed.");
      }
    } catch (error) {
      toast.error("Error updating task.");
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);

      // Preview the selected image
      const reader = new FileReader();
      reader.onload = (event) => {
        setImg(event.target.result); // Base64 image for preview
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setFile(null);
      setImg(null);
    }
  };

  return (
    <div className="edit__task__group__main_container">
      <div className="edit__task__group__main_container1">
        <div className="edit__task__group__main_container2">
          <p className="edit__taskgroup__task_heading">
            {taskData ? "Edit Task" : "Add Task"}
          </p>

          <div className="edit_task_group_form_container">
            <div className="edit_task_group_form_container1">
              <p className="edit_task_group_form_label">Task Title</p>
              <input
                className="edit__taskgroup__form_placeholder"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter Title of the Task"
              />
            </div>

            <div className="edit_task_group_form_container1">
              <p className="edit_task_group_form_label">Task Description</p>
              <textarea
                className="edit__taskgroup__form_placeholder1"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter Description of the Task"
                style={{ resize: "none" }}
              />
            </div>
          </div>

          <div className="edit__taskgroup__button__container">
            <button
              className="edit__taskgroup__publish__button"
              onClick={handlePublish}
            >
              {taskData ? "Update Task" : "Publish"}
            </button>
            <button
              onClick={handleTaskClick}
              className="edit__taskgroup__cancel__button"
            >
              Cancel
            </button>
          </div>
        </div>

        <div className="upload__task__container">
          <div className="upload__task__container1">
            <p className="upload__task__heading">Upload Task Image</p>
            <p className="upload__task__imagesize__heading">Image size: 52x52</p>
          </div>

          <div
            className="uploaded-image-preview"
            onClick={() => document.getElementById("file-input").click()}
          >
            {img ? (
              <img src={img} alt="Uploaded Preview" />
            ) : (
              <p>Click here or drag and drop to upload an image</p>
            )}
            <input
              id="file-input"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTaskGroup;
