import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./EditRoadMap.css";
import { useDispatch, useSelector } from "react-redux";
import { updateRoadmap } from "../../services/redux/middleware/getEvent";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditRoadMap() {
  const location = useLocation();
  const { roadmapItem } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { roadmapStatus, roadmapError } = useSelector(
    (state) => state.getAllEvenst
  );

  const [title, setTitle] = useState(roadmapItem?.title || "");
  const [update1, setUpdate1] = useState(roadmapItem?.update1 || "");
  const [update2, setUpdate2] = useState(roadmapItem?.update2 || "");
  const [update3, setUpdate3] = useState(roadmapItem?.update3 || "");
  const [month, setMonth] = useState(roadmapItem?.month || "");
  const [year, setYear] = useState(roadmapItem?.year || "");

  useEffect(() => {
    console.log("roadmapItem:", roadmapItem);
  }, [roadmapItem]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!title || !update1 || !update2 || !update3 || !month || !year) {
      toast.error("Please fill in all fields");
      return;
    }

    if (!roadmapItem?._id) {
      toast.error("Roadmap ID is missing!");
      return;
    }
    toast.success("Roadmap updated successfully!");
    navigate("/update");

    const updatedRoadmapData = {
      id: roadmapItem?._id,
      title,
      update1,
      update2,
      update3,
      month,
      year,
    };

    console.log("Dispatching updateRoadmap with:", updatedRoadmapData);
    dispatch(updateRoadmap(updatedRoadmapData));
  };

  return (
    <>
      <div className="main_container">
        <h2 className="roadmap_text_h">Edit Roadmap</h2>
        <div className="ovrall_input_container">
          <div className="input_text_Roadmap_container">
            <label className="roadmap-titel-text">Roadmap Title</label>
            <input
              className="Title_input"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter Title of the Roadmap"
            />
          </div>
          <div className="input_text_Roadmap_container">
            <label className="roadmap-titel-text">Update 1</label>
            <textarea
              className="update_Text_input"
              value={update1}
              onChange={(e) => setUpdate1(e.target.value)}
              placeholder="Enter Update 1"
              style={{ resize: "none" }}
            />
          </div>
          <div className="input_text_Roadmap_container">
            <label className="roadmap-titel-text">Update 2</label>
            <textarea
              className="update_Text_input"
              value={update2}
              onChange={(e) => setUpdate2(e.target.value)}
              placeholder="Enter Update 2"
              style={{ resize: "none" }}
            />
          </div>
          <div className="input_text_Roadmap_container">
            <label className="roadmap-titel-text">Update 3</label>
            <textarea
              className="update_Text_input"
              value={update3}
              onChange={(e) => setUpdate3(e.target.value)}
              placeholder="Enter Update 3"
              style={{ resize: "none" }}
            />
          </div>
          <div className="input_text_Roadmap_container">
            <label className="roadmap-titel-text">Month</label>
            <input
              className="Title_input"
              type="month"
              id="month"
              name="month"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            />
          </div>
          <div className="input_text_Roadmap_container">
            <label className="roadmap-titel-text">Year</label>
            <input
              className="Title_input"
              type="text"
              value={year}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) setYear(value);
              }}
              placeholder="Enter Year"
            />
          </div>

          <div className="Proposal_Button_Main_1">
            <button
              onClick={handleSubmit}
              className="Proposal_Button_Main_1_Btn1"
            >
              Publish
            </button>
            <button
              onClick={() => navigate("/update")}
              className="Proposal_Button_Main_1_Btn2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
