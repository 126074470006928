import "./App.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import SideDrawer from "./Components/Drawer/SideDrawer";
import TaskPage from './Pages/Tasks/Tasks'

import TaskGroupPage from './Pages/TaskGroup/TaskGroup'
import EditTaskGroupPage from "./Pages/EditTaskGroup/EditTaskGroup";
import RefferalsPage from "./Pages/Refferals/Refferals"
import RankingPage from './Pages/Rankings/Ranking'
import UserPage from "./Pages/Users/UserPage";
import UpdatePage from "./Pages/Updates/update";
import ProposalPage from './Pages/Proposals/Proposal'
import AddProposal from "./Pages/AddProposal/AddProposal";
import AddTask from "./Pages/AddTask/AddTask";
import EditTask from "./Pages/EditTask/EditTask";
import FeaturedTask from "./Pages/FeaturedTask/FeaturedTask";

import EditProposal from "./Pages/EditProposal/EditProposal";
import NewTask from "./Pages/NewTask/NewTask";
import EditFeature from "./Pages/EditFeature/EditFeature";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoadMap from "./Pages/RoadMap/RoadMap";
import RoadMap_input from "./Pages/RoadMap_input/RoadMap_input";
import Add_news from "./Pages/Add_news/Add_news";
import EditNews from "./Pages/EditNews/EditNews";
import EditRoadMap from "./Pages/EditRoadMap/EditRoadMap";
import ScreenLoader from "./Components/ScreenLoader/ScreenLoader";
function App() {
  const DashboardLayout = () => (
    <>
      <SideDrawer showSidebar={true} style={{ overflowX: "hidden" }}>
        <Outlet />
      </SideDrawer>
    </>
  );
  return (
    <>
      <BrowserRouter>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
        <Routes>
          <Route element={<DashboardLayout />}>
            <Route path="/" element={<TaskPage />} />
            <Route path="/taskadd" element={<AddTask />} />
            <Route path="/FeaturedTask" element={<FeaturedTask/>} />
            <Route path="/EditFeature" element={<EditFeature/>} />
            <Route path="/NewTask" element={<NewTask/>} />
            <Route path="/edittask" element={<EditTask />} />
            <Route path="/taskGroup" element={<TaskGroupPage />} />
            <Route path="/editTaskGroup" element={<EditTaskGroupPage />} />
            <Route path="/refferals" element={<RefferalsPage />} />
            <Route path="/ranking" element={<RankingPage />} />
            <Route path="/users" element={<UserPage />} />
            <Route path="/update" element={<UpdatePage />} />
            <Route path="/ScrrenLoader" element={<ScreenLoader/>} />
            <Route path="/dao" element={<ProposalPage />} />
            <Route path="/editproposal" element={<EditProposal />} />
            <Route path="/addproposal" element={<AddProposal />} />
            <Route path="/RoadMap" element={<RoadMap/>} />
            <Route path="/RoadMapAdd" element={<RoadMap_input/>} />
            <Route path="/EditRoadMap" element={<EditRoadMap/>} />
            <Route path="/Addnews" element={<Add_news/>} />
            <Route path="/EditNews" element={<EditNews/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;