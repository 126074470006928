import React from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deleteTask } from "../../services/redux/middleware/getEvent";
import "./DeleteTask.css";
import {  toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const DeleteTaskModal = ({ show, handleClose, taskId }) => {
  const dispatch = useDispatch();

  const handleDeleteTask = () => {
  
    if (taskId) {
     
      dispatch(deleteTask(taskId))
        .then(() => {
          toast.success("Task deleted successfully");
          handleClose();  
        })
        .catch((error) => {
          toast.error("Failed to delete task: " + error.message); 
          handleClose();  
        });
    } else {
      toast.error("Invalid task ID");  
      handleClose();
    }
  };

  return (
    <Modal
      className="modal__main__container"
      show={show}  // Control visibility of modal via show prop
      onHide={handleClose}  // Close modal on hide
      centered
    >
      <ModalBody>
        <ModalHeader className="close__image_container-1">
          <div className="close__image_container" onClick={handleClose}>
            <img src="/Images/Modal/close.svg" alt="Close" />
          </div>
        </ModalHeader>
        <div className="delete__task__modal_container">
          <div className="delete__task__modal__container_1">
            <div className="delete__task__modal__heading_container">
              <img
                className="logo__modal__image"
                src="/Images/Modal/logo.svg"
                alt="Logo"
              />
              <p className="delete__task_text">Delete Task</p>
            </div>

            <div className="delete__modal__button_container">
              <button
                onClick={handleClose}  // Cancel button closes the modal without doing anything
                className="delete__modal_cancel_button"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteTask}  // Delete button triggers the deletion process
                className="delete__modal_delete_button"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteTaskModal;
