import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addQuest } from "../../services/redux/middleware/getEvent";
import { Dropdown } from "react-bootstrap";
import { DropzoneArea } from "material-ui-dropzone";
import { useNavigate } from "react-router-dom";
import "./AddTask.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddTask() {
  const [selectedGroup, setSelectedGroup] = useState("Select Group");
  const [preview, setPreview] = useState("");
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskpoints, setTaskpoints] = useState("");
  const [taskUrl, setTaskUrl] = useState("");
  const [taskImage, setTaskImage] = useState(null);
  const [loading, setLoading] = useState(false); 

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTaskClick = () => navigate("/");

  const handleSelectGroup = (group) => {
    if (group === "Featured Task") {
      setSelectedGroup("featured");
    } else if (group === "New Task") {
      setSelectedGroup("quest");
      console.log("Selected Group:", group);
    }
  };

  const handleImageChange = (files) => {
    if (files && files.length > 0) {
      setTaskImage(files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleManualFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setTaskImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    if (loading) return; 
    if (!taskTitle || !taskDescription || !taskpoints || !taskUrl || !taskImage || selectedGroup === "Select Group") {
      toast.error("All fields are required!");
      return;
    }
  
    setLoading(true); 
  
    const formData = new FormData();
    formData.append("title", taskTitle);
    formData.append("description", taskDescription);
    formData.append("questType", selectedGroup);
    formData.append("points", taskpoints);
    formData.append("url", taskUrl);
    formData.append("priority", "medium");
    formData.append("file", taskImage);
  
    dispatch(addQuest(formData))
      .then(() => {
        toast.success("Task added successfully!");
        
       
        if (selectedGroup === "quest") {
          navigate("/NewTask");
        } else if (selectedGroup === "featured") {
          navigate("/FeaturedTask");
        }
      })
      .catch((error) => {
        console.error("Error adding task:", error);
        toast.error(`Error adding task: ${error.message}`);
      })
      .finally(() => {
        setLoading(false); 
      });
  };
  
  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <div className="edit__task__main__container">
      <div className="edit__task__right__container">
        <div className="edit__task__main__container1">
          <p className="add__task__heading_1">Add Task</p>
          <div className="edit__task__form__container">
            <div className="edit__task__form__container1">
              <p className="edit__task__form_label"> Task Title</p>
              <input
                className="edit__task__form_placeholder"
                type="text"
                value={taskTitle}
                onChange={(e) => setTaskTitle(e.target.value)}
                placeholder="Enter Title of the Task"
              />
            </div>
            <div className="edit__task__form__container1">
              <p className="edit__task__form_label">Task Description</p>
              <textarea
                className="edit__task__textarea_placeholder"
                value={taskDescription}
                onChange={(e) => setTaskDescription(e.target.value)}
                placeholder="Enter Description of the Task"
                style={{ resize: 'none' }}
              />
            </div>
            <div className="edit__task__form__container1">
              <p className="edit__task__form_label">xMAG Task Amount</p>
              <input
                className="edit__task__form_placeholder"
                type="text"
                value={taskpoints}
                onChange={(e) => {
                  const value = e.target.value;

                  if (/^\d*\.?\d*$/.test(value)) {
                    setTaskpoints(value);
                  }
                }}
                placeholder="Enter xMAG Amount of the Task"
              />
            </div>

            <div className="edit__task__form__container1">
              <p className="edit__task__form_label">Task URL Link</p>
              <input
                className="edit__task__form_placeholder"
                type="text"
                value={taskUrl}
                onChange={(e) => setTaskUrl(e.target.value)}
                placeholder="Input Task URL Link"
              />
            </div>
            <div className="edit__task__form__container1">
              <p className="edit__task__form_label">Choose Task Group</p>
              <div className="edit__task__placeholder_container">
                <input
                  className="edit__task__form_placeholder-1"
                  type="text"
                  value={selectedGroup}
                  readOnly
                  style={{
                    color:
                      selectedGroup === "Select Group" ? "#bcbfcc" : "#1e1e24",
                  }}
                />
                <Dropdown className="custom-dropdown-1">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="dropdown-toggle-custom"
                  >
                    <img
                      src="/Images/EditTask/dropdown.svg"
                      alt="dropdown icon"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-custom">
                    <Dropdown.Item
                      className="dropdown__heading_1"
                      onClick={() => handleSelectGroup("New Task")}
                    >
                      Quest
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown__heading_1"
                      onClick={() => handleSelectGroup("Featured Task")}
                    >
                      Featured Task
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="edit__task__button__container">
            <button
              onClick={handleSubmit}
              className="edit__task__publish__button"
              disabled={loading} 
            >
              {loading ? "Publishing..." : "Publish"}
            </button>
            <button
              onClick={handleTaskClick}
              className="edit__task__cancel__button"
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="upload__task__container">
          <div className="upload__task__container1">
            <p className="upload__task__heading">Upload Task Image</p>
            <p className="upload__task__imagesize__heading">
              Image size: 52x52
            </p>
          </div>
          {!preview && (
            <DropzoneArea
              id="upload_image"
              filesLimit={1}
              acceptedFiles={["image/*"]}
              maxFileSize={5000000}
              showAlerts={["error", "info"]}
              dropzoneText="Drag & Drop or"
              value={taskImage}
              accept="image/*"
              onChange={handleImageChange}
              className="edit__task__form_placeholder"
            />
          )}

          {preview && (
            <div
              className="uploaded-image-preview"
              onClick={handleImageClick}
            >
              <img
                src={preview}
                alt="Uploaded Preview"
              />
            </div>
          )}
          <input
            id="fileInput"
            type="file"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleManualFileChange}
          />
        </div>
        
      </div>
    </div>
  );
}

export default AddTask;
