import React from 'react'
import './RoadMap.css'

export default function RoadMap() {
    const rows = [
        { id: 1, month: "December", year: 2024, title: "Lorem Ipsum", status: "Finished" },
        { id: 2, month: "December", year: 2024, title: "Lorem Ipsum", status: "Finished" },
        { id: 3, month: "December", year: 2024, title: "Lorem Ipsum", status: "Unfinished" },
        { id: 4, month: "December", year: 2024, title: "Lorem Ipsum", status: "Finished" },
        { id: 5, month: "December", year: 2024, title: "Lorem Ipsum", status: "Finished" },
        { id: 6, month: "December", year: 2024, title: "Lorem Ipsum", status: "Finished" },
        { id: 7, month: "December", year: 2024, title: "Lorem Ipsum", status: "Unfinished" },
        { id: 8, month: "December", year: 2024, title: "Lorem Ipsum", status: "Finished" },
        { id: 9, month: "December", year: 2024, title: "Lorem Ipsum", status: "Finished" },
        { id: 10, month: "December", year: 2024, title: "Lorem Ipsum", status: "Finished" },
        { id: 11, month: "December", year: 2024, title: "Lorem Ipsum", status: "Unfinished" },
        { id: 12, month: "December", year: 2024, title: "Lorem Ipsum", status: "Finished" },
        { id: 13, month: "December", year: 2024, title: "Lorem Ipsum", status: "Finished" },
        { id: 14, month: "December", year: 2024, title: "Lorem Ipsum", status: "Finished" },
        { id: 15, month: "December", year: 2024, title: "Lorem Ipsum", status: "Unfinished" },
        { id: 16, month: "December", year: 2024, title: "Lorem Ipsum", status: "Finished" },
        // Add more rows as needed
      ];
  return (
    <>
     <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Month</th>
            <th>Year</th>
            <th>Title</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{row.month}</td>
              <td>{row.year}</td>
              <td>{row.title}</td>
              <td className={`status ${row.status.toLowerCase()}`}>{row.status}</td>
              <td className="action-buttons">
                <div className='edit-icon'>
                <img src='/Images/RoadmapImges/Edit.svg'/>
                </div>
                <div className='edit-icon'>
               <img  src='/Images/RoadmapImges/dlt.svg'/></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    
    </>
  )
}
