import "./EditNews.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux"; 
import { updateNews } from "../../services/redux/middleware/getEvent";
import { toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
export default function EditNews() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch(); 

  const [newsData, setNewsData] = useState({
    title: "",
    description: "",
    date: "",
    time: "",
    newsLink: "",
  });
  const [error, setError] = useState(""); 


  useEffect(() => {
    if (location.state && location.state.newsItem) {
      setNewsData(location.state.newsItem);
    } else {
      
      navigate("/news");
    }
  }, [location.state, navigate]);

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewsData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!newsData.title || !newsData.description || !newsData.time) {
      setError("Please fill in all required fields.");
      return;
    }
  
    console.log("Submitting data:", newsData); 
  
    const updatedData = {
      id: newsData._id, 
      title: newsData.title,
      description: newsData.description,
      date: newsData.date,
      time: newsData.time,
      newsLink: newsData.newsLink,
    };
  
    dispatch(updateNews(updatedData))
      .then(() => {
        toast.success("News updated successfully!"); 
        navigate("/update");
        window.location.reload(); 
      })
      .catch((error) => {
        setError("Error updating news: " + error.message);
        toast.error("Failed to update news: " + error.message); 
      });
  };
  

  return (
    <div className="main_container">
      <h2 className="roadmap_text_h">Edit News</h2>
      {error && <div className="error-message">{error}</div>} 
      <form className="ovrall_input_container" onSubmit={handleSubmit}>
        <div className="input_text_Roadmap_container">
          <label className="roadmap-titel-text">News Title</label>
          <input
            className="Title_input"
            type="text"
            name="title"
            value={newsData.title}
            placeholder="Enter Title of the News"
            onChange={handleChange}
            required
          />
        </div>

        <div className="input_text_Roadmap_container">
          <label className="roadmap-titel-text">News Description</label>
          <textarea
            className="update_Text_input"
            name="description"
            value={newsData.description}
            placeholder="Enter Description of the News"
            onChange={handleChange}
            required
            style={{ resize: 'none' }}
          />
        </div>

        <div className="input_text_Roadmap_container">
          <label className="roadmap-titel-text">Date</label>
          <input
            className="Title_input"
            type="date"
            name="date"
            value={newsData.date}
            onChange={handleChange}
          />
        </div>

        <div className="input_text_Roadmap_container">
          <label className="roadmap-titel-text">Time</label>
          <input
            className="Title_input"
            type="time"
            name="time"
            value={newsData.time}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input_text_Roadmap_container">
          <label className="roadmap-titel-text">News Link</label>
          <input
            className="Title_input"
            type="url"
            name="newsLink"
            value={newsData.newsLink}
            placeholder="Input News Link"
            onChange={handleChange}
          />
        </div>

        <div className="Proposal_Button_Main_1">
          <button className="Proposal_Button_Main_1_Btn1" type="submit">
            Publish
          </button>
          <button
            className="Proposal_Button_Main_1_Btn2"
            type="button"
            onClick={() => navigate("/update")} 
          >
            Cancel
          </button>
        </div>

      </form>
    </div>
  );
}
